import axios, { AxiosRequestConfig } from "axios"

type apiNrj = {
    apiCall: string,
    config? : any,
    whchServer?: string
}

export const nrjAxios = (props: apiNrj) => {
    let wch : string = props.whchServer ? props.whchServer : ""
    if (props.apiCall.indexOf("https:") === -1) {
        if (!wch){
            props.apiCall = "https://biowaste.in/" + props.apiCall
        } else {
            if (wch == "tsk"){
                props.apiCall = "https://www.thetaskmate.in/" + props.apiCall
            } else {
                props.apiCall = "https://biowaste.in/" + props.apiCall
            }
        }        
    }
    if (wch == "tsk" || props.apiCall.indexOf("taskmate.in")>-1 || props.apiCall.indexOf("vnenterpr")>-1 ) {
        return axios.get(props.apiCall);
    } else {
        return axios.post(props.apiCall, props.config);
    }
}


export const useNrjAxios = (props: apiNrj) => {
    if (props.apiCall.indexOf("https:") === -1) {
        props.apiCall = "https://www.thetaskmate.in/" + props.apiCall
    }
    return axios.get(props.apiCall)
}


export const nrjAxiosRequest = (url:string, data:any) => {
    if (url.indexOf("https:") === -1) {
        url = 'https://api.swachhtaabhiyan.com/' + url
    }
    let config: AxiosRequestConfig = {
        method:'post',
        url:url,
        data:data
    }
    return axios.request(config);
}
