import React, { useReducer, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import utilities, { GetResponseWnds, convertFldValuesToJson, createGetApi, dataStr_ToArray, getCmpId, getUsrId, postLnxSrvr, svLnxSrvr, tellWndsServer, tellWndsServer2 } from '../../utilities/utilities'
import { Button, SvgIcon } from "@mui/material";

import { nrjAxios, nrjAxiosRequest, useNrjAxios } from '../../Hooks/useNrjAxios';
import { Navigate, useNavigate } from "react-router-dom";
import { getFldValue, useGetFldValue } from "../../Hooks/useGetFldValue";
import WtrInput from '../../components/reusable/nw/WtrInput';
import WtrRsSelect from '../../components/reusable/nw/WtrRsSelect';
import { validForm } from '../../Hooks/validForm';
import { Toaster } from '../../components/reusable/Toaster';
import NrjRsDt from '../../components/reusable/NrjRsDt';
import { hasPointerEvents } from '@testing-library/user-event/dist/utils';
import SaveIcon from "@mui/icons-material/Save";
import { useEffectOnce } from 'react-use';


const ACTIONS = {
    TRIGGER_GRID: "grdtrigger",
    NEWROWDATA: "newrow",
    RANDOM: "rndm",
    TRIGGER_FORM: "trgfrm",
    FORM_DATA: "frmdata",
    SETFORM_DATA: "setfrmdata",
    MAINID: "mnid",
    CHECK_REQ: "chckreq",
    CHECK_REQDONE: "chckreqdn",
    SETGID: "gd",
    NEWFRMDATA: "frmdatanw",
    DISABLE: 'disable'
};

const initialState = {
    triggerG: 0,
    nwRow: [],
    rndm: 0,
    trigger: 0,
    textDts: "",
    mainId: 0,
    errMsg: [],
    openDrwr: false,
    frmData: "",
    gid: "",
};

type purBill = {
    triggerG: number;
    nwRow: any;
    rndm: number;
    trigger: number;
    textDts: string;
    mainId: number;
    errMsg: any;
    openDrwr: boolean;
    frmData: string;
    gid: string;
};

type act = {
    type: string;
    payload: any;
};

const reducer = (state: purBill, action: act) => {
    let newstate: any = { ...state };
    switch (action.type) {
        case ACTIONS.NEWFRMDATA:
            newstate.textDts = action.payload;
            return newstate;
        case ACTIONS.MAINID:
            newstate.mainId = action.payload;
            newstate.gid = "";
            newstate.rndm += 1;
            return newstate;
        case ACTIONS.TRIGGER_GRID:
            newstate.triggerG = action.payload;
            return newstate;
        case ACTIONS.TRIGGER_FORM:
            newstate.trigger = action.payload;
            if (action.payload === 0) {
                newstate.textDts = "";
                newstate.frmData = "";
                newstate.mainId = 0;
            }
            return newstate;
        case ACTIONS.NEWROWDATA:
            newstate.nwRow = action.payload;
            newstate.triggerG = 1;
            return newstate;
        case ACTIONS.RANDOM:
            newstate.rndm += 1;
            return newstate;
        case ACTIONS.FORM_DATA:
            let dta: string = "";
            let fldN: any = utilities(2, action.payload, "");
            if (newstate.textDts) {
                dta = newstate.textDts + "=";
                let d: any = utilities(1, dta, fldN);
                if (d) {
                    dta = d;
                } else {
                    dta = "";
                }
            }
            dta += action.payload;
            newstate.textDts = dta;
            newstate.frmData = dta;
            return newstate;
        case ACTIONS.SETFORM_DATA:
            newstate.frmData = action.payload;
            newstate.textDts = action.payload;
            return newstate;
        case ACTIONS.CHECK_REQ:
            newstate.errMsg = action.payload;
            newstate.openDrwr = true;
            return newstate;
        case ACTIONS.CHECK_REQDONE:
            newstate.errMsg = [];
            newstate.openDrwr = false;
            return newstate;
        case ACTIONS.SETGID:
            newstate.gid = action.payload;
            return newstate;
        case ACTIONS.DISABLE:
            if (action.payload == 1) {
                if (newstate.disableA == 1) {
                    newstate.disableA = 0
                } else {
                    newstate.disableA = 1
                }
                return newstate
            } else if (action.payload == 2) {
                if (newstate.disableB == 1) {
                    newstate.disableB = 0
                } else {
                    newstate.disableB = 1
                }
                return newstate
            }
    }
};


const HCFMaster = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [gid, setGid] = useState('')
    const [showMessage, setShowMessage] = useState<any>({ message: [] })
    const hcfLoginId = !isNaN(Number(sessionStorage.getItem('mainid'))) ? Number(sessionStorage.getItem('mainid')) : 0;
    const reqFlds = [
        { fld: 'hsp', msg: 'Select the Hospital / Lab', chck: '1[length]' },
        { fld: 'lblno', msg: 'Enter Label Number', chck: '1[length]' },
        { fld: 'adra', chck: '1[length]', msg: 'Enter Address I' },
        { fld: 'cty', chck: '1[length]', msg: 'Enter City' },
        { fld: 'stt', chck: '1[length]', msg: 'Enter State' },
        { fld: 'nbd', chck: '1[length]', msg: 'Enter No of Beds' },
        { fld: 'ctg', chck: '1[length]', msg: 'Select Category' },
        { fld: 'pnc', chck: '6[length]', msg: 'Enter Pin Code' },
        { fld: 'pcw', chck: '5[length]', msg: 'Enter Pollution Board No' },
        { fld: 'cntrprs', chck: '5[length]', msg: 'Enter Contact Person' },
        { fld: 'ppl', chck: '1[length]', msg: 'Enter Entity' },
        { fld: 'mob', chck: '[mob]', msg: 'Enter Mobile' },
        { fld: 'phn', chck: '[mob]', msg: 'Enter Phone' },
        { fld: 'eml', chck: '[email]', msg: 'Enter E Mail' },
        { fld: 'psw', chck: '[psw]]', msg: 'Enter Password' },
    ];

    const GetDataValue = (data: string, fld: string) => {
        let vl: string = useGetFldValue(data, fld);
        return vl;
    }
    const getGid = () => {
        let g: any = utilities(3, "", "");
        dispatch({ type: ACTIONS.SETGID, payload: g });
        return g;
    };

    const onChangeDts = (data: string) => {
        dispatch({ type: ACTIONS.FORM_DATA, payload: data });
    };

    const HandleSaveClick = () => {
        let api: string = state.textDts;
        api = postLnxSrvr(api, "", "s", "prj0hcfmstr", state.mainId, "");
        let gd: string = getGid();
        setGid(gd);
        let jsonData = convertFldValuesToJson(state.textDts);
        let body = {
            data: [
                [
                    {
                        gid: gd,
                        txtfl: 'hcfreg',
                        cmp: getCmpId(),
                        usr: getUsrId(),
                    }
                ],
                [
                    jsonData
                ],


            ],

        };

        return nrjAxiosRequest("svfrm", body);
    };

    const svdQry = (data: any) => {
        refetchW();
    }

    const saveClick = () => {
        let api: string = state.textDts;
        let msg: any = validForm(api, reqFlds);
        setShowMessage({ message: msg, type: 'error' });
        if (msg && msg[0]) {
            dispatch({ type: ACTIONS.CHECK_REQ, payload: msg });
            setTimeout(function () {
                dispatch({ type: ACTIONS.CHECK_REQDONE, payload: 1 });
            }, 2500);
            return;
        }
        dispatch({ type: ACTIONS.DISABLE, payload: 1 })
        if(sessionStorage.getItem('mainid')){
            refetch();
        }
        else{
            checkDuplicate();
        }
    }

    const duplicateMobile = () => {
        let mobileNo: string = getFldValue(state.textDts, 'phn')
        let api: string = createGetApi("db=nodb|dll=hospdll|fnct=bx", 'stt1082=0=' + mobileNo);
        return nrjAxios({ apiCall: api });
    }
    const duplicateMobileSuccess = (data: any) => {
        let dt: string = GetResponseWnds(data);
        if (dt) {
            if(dt.includes('id][') && getFldValue(dt, 'id')){
                setShowMessage({message:['Duplicate Mobile No, Found another Hospital with same Mobile Number'], type:'error'})
            }
            else{
                refetchDuplicateMail();
            }  
        }
        else{
            refetchDuplicateMail();
        }
    }
    const duplicateEmail = () => {
        let email: string = getFldValue(state.textDts, 'eml')
        let api: string = createGetApi("db=nodb|dll=hospdll|fnct=bx", 'stt1084=0=' + email);
        return nrjAxios({ apiCall: api });
    }
    const duplicateEmailSuccess = (data: any) => {
        let dt: any = GetResponseWnds(data);
        if (dt) {
            if(dt.includes('id][') && getFldValue(dt, 'id')){
                setShowMessage({message:['Duplicate Email, Found another Hospital with same Email'], type:'error'})
            }
            else{
                refetch();
            }
        }
        else{
            refetch();
        }
    }



    const { data: dataMail, refetch: refetchDuplicateMail } = useQuery({
        queryKey: ['mailDuplicateCheck', state.textDts],
        queryFn: duplicateEmail,
        enabled: false,
        staleTime: Number.POSITIVE_INFINITY,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onSuccess: duplicateEmailSuccess,
    })

    const { data: dataCheck, refetch: checkDuplicate } = useQuery({
        queryKey: ['mobileDuplicateCheck', state.textDts],
        queryFn: duplicateMobile,
        enabled: false,
        staleTime: Number.POSITIVE_INFINITY,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onSuccess: duplicateMobileSuccess,
    })

    const { data, refetch } = useQuery({
        queryKey: ['svQry', state.mainId, state.rndm],
        queryFn: HandleSaveClick,
        enabled: false,
        staleTime: Number.POSITIVE_INFINITY,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onSuccess: svdQry,
    })

    useEffectOnce(() => {
        refetchHcfData();
    })

    const GetData = () => {
        let mainid: string = sessionStorage.getItem('mainid') || '0';
        let api: string = createGetApi(
            "db=nodb|dll=hospdll|fnct=102",
            mainid + "=sti1073"
        );
        return useNrjAxios({ apiCall: api });
    };

    const ShowData = (data: any) => {
        if (data && data.data && data.data[0] && data.data[0]["Status"]) {
            let dt: string = data.data[0]["Data"];
            dispatch({ type: ACTIONS.SETFORM_DATA, payload: dt })
            //setFrmData(dt);
        }
    };
    const { data: datab, refetch: refetchHcfData } = useQuery({
        queryKey: ["getQry", state.mainId],
        queryFn: GetData,
        enabled: false,
        staleTime: Number.POSITIVE_INFINITY,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onSuccess: ShowData,
    });

    const clrFunct = () => {
        dispatch({ type: ACTIONS.TRIGGER_FORM, payload: 1 });
        setTimeout(() => {
            dispatch({ type: ACTIONS.TRIGGER_FORM, payload: 0 });
        }, 300)
    }

    const Wnd = () => {
        let g: string = gid;
        let api: string = tellWndsServer2(g);
        return useNrjAxios({ apiCall: api });
    };

    const svd = (dataW: any) => {
        let dt: string = GetResponseWnds(dataW);
        dt = getFldValue(dt, "id");
        if (Number(dt) > -1) {
            setShowMessage({
                message: ["Data Saved Successfully"],
                type: "success",
            });

        }
        let ary: any = dataStr_ToArray(state.textDts);
        // dispatch({ type: ACTIONS.NEWROWDATA, payload: ary });
        // dispatch({ type: ACTIONS.TRIGGER_FORM, payload: 1 });
        // setTimeout(() => {
        //     dispatch({ type: ACTIONS.TRIGGER_FORM, payload: 0 });
        // }, 300);

        dispatch({ type: ACTIONS.MAINID, payload: 0 });
        dispatch({ type: ACTIONS.RANDOM, payload: 1 });
    };
    const { data: dataW, refetch: refetchW } = useQuery({
        queryKey: ["svWnd", gid],
        queryFn: Wnd,
        enabled: false,
        staleTime: Number.POSITIVE_INFINITY,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onSuccess: svd,
    });

    const navigate = useNavigate();

    const navigateToAr = () => {
        navigate('/hcfAr');
    }


    const logout = () => {
        localStorage.clear();
        sessionStorage.clear();
        navigate("/login")
    }


    const Seperator = (props: any) => {


        return (
            <>
                <div className="mt-7">
                    <div className="font-semibold" style={{ color: '#86c6d9' }}>
                        {/* <div className="font-semibold" style={{ color: '#009ED6' }}> */}
                        {props.heading}
                    </div>
                    <div className="mt-2" style={{ border: '1px solid #86c6d9' }}>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className='px-3'>
            <div className='flex py-4 justify-end'>
                <Button
                    size="medium"
                    style={{ backgroundColor: "#86c6d9" }}
                    variant="contained"
                    color="success"
                    disabled={false}
                    onClick={logout}>
                    Logout
                </Button>
            </div>
            <div className="rounded" style={{ boxShadow: '0px 0px 20px 0px #00000029' }}>
                <div className="p-7 rounded text-black" style={{ background: 'linear-gradient(90.29deg, #86c6d9 0%, #aed6e0 100%)' }}>
                    <div className="text-2xl font-bold">Health Care Facility</div>
                    <div className="">please provide the requested information below</div>
                </div>
                <div className="mx-7">
                    <Seperator heading="HCF Detail"></Seperator>
                    <div className=" mt-4 grid grid-cols-2 gap-x-8 gap-y-4">
                        <WtrInput
                            displayFormat="1"
                            Label="HCF Name"
                            fldName="hsp"
                            idText="txthsp"
                            onChange={onChangeDts}
                            dsabld={false}
                            callFnFocus=""
                            dsbKey={false}
                            validateFn="5[length]"
                            allowNumber={false}
                            unblockSpecialChars={true}
                            selectedValue={state.frmData}
                            clrFnct={state.trigger}
                            speaker={"Enter Name of HCF"}

                            delayClose={1000}
                            placement="right"
                            ClssName=""
                        ></WtrInput>


                        <WtrInput displayFormat='1' Label='Label No' fldName='lblno' idText='txtlblno' onChange={onChangeDts} dsabld={false} callFnFocus='' dsbKey={false} upprCase={false} validateFn='20[length]' allowNumber={false} selectedValue={state.frmData} clrFnct={state.trigger} speaker={'Enter Label No'} delayClose={1000} placement='left' ClssName='' ></WtrInput>
                        <WtrInput
                            displayFormat="1"
                            Label="SPCB Code"
                            fldName="pcw"
                            idText="txtpcw"
                            onChange={onChangeDts}
                            dsabld={false}
                            callFnFocus=""
                            dsbKey={false}
                            validateFn="5[length]"
                            maxLenght={5}
                            allowNumber={true}
                            speaker={"Enter SPCB Code"}
                            selectedValue={state.frmData}
                            clrFnct={state.trigger}
                        ></WtrInput>

                        <WtrInput
                            displayFormat="1"
                            Label="No of Beds"
                            fldName="nbd"
                            idText="txtnbd"
                            onChange={onChangeDts}
                            dsabld={false}
                            callFnFocus=""
                            dsbKey={false}
                            minValue={-1}
                            validateFn="1[length]"
                            allowNumber={true}
                            ToolTip="Enter numbers only"
                            selectedValue={state.frmData}
                            clrFnct={state.trigger}
                            delayClose={1000}
                            speaker={"Enter No of Beds"}

                        ></WtrInput>





                        <WtrRsSelect
                            displayFormat="1"
                            Label="HCF Type"
                            fldName="ctgid"
                            idText="txtctgid"
                            onChange={onChangeDts}
                            selectedValue={state.frmData}
                            clrFnct={state.trigger}
                            allwZero={"1"}
                            fnctCall={false}
                            dbCon={"shrtrm"}
                            typr={"633"}
                            dllName={""}
                            fnctName={""}
                            parms={""}
                            speaker={"Select Type of HCF"}
                            allwSrch={true}
                        ></WtrRsSelect>

                    </div>

                    <Seperator heading="Address Detail"></Seperator>

                    <div className=" mt-4 grid grid-cols-2 gap-x-8 gap-y-4">
                        <WtrInput
                            displayFormat="1"
                            Label="Address I"
                            fldName="adra"
                            idText="txtadra"
                            onChange={onChangeDts}
                            dsabld={false}
                            callFnFocus=""
                            dsbKey={false}

                            validateFn="1[length]"
                            allowNumber={false}
                            unblockSpecialChars={true}
                            selectedValue={state.frmData}
                            clrFnct={state.trigger}
                            speaker={"Enter Address"}

                        ></WtrInput>
                        <WtrInput
                            displayFormat="1"
                            Label="Address II"
                            fldName="adrb"
                            idText="txtadrb"
                            onChange={onChangeDts}
                            dsabld={false}
                            callFnFocus=""
                            dsbKey={false}

                            validateFn=""
                            unblockSpecialChars={true}
                            allowNumber={false}
                            selectedValue={state.frmData}
                            clrFnct={state.trigger}
                        ></WtrInput>
                        <WtrInput
                            displayFormat="1"
                            Label="Address III"
                            fldName="adrc"
                            idText="txtadrc"
                            onChange={onChangeDts}
                            dsabld={false}
                            callFnFocus=""
                            dsbKey={false}

                            validateFn=""
                            allowNumber={false}
                            unblockSpecialChars={true}
                            selectedValue={state.frmData}
                            clrFnct={state.trigger}
                        ></WtrInput>

                        <WtrInput displayFormat='1' Label='District' fldName='dstr' idText='txtdstr' onChange={onChangeDts} dsabld={false} callFnFocus='' dsbKey={false} upprCase={false} validateFn='' allowNumber={false} selectedValue={state.frmData} clrFnct={state.trigger} ></WtrInput>


                        <WtrRsSelect
                            displayFormat="1"
                            Label="City"
                            fldName="ctyid"
                            idText="txtctyid"
                            onChange={onChangeDts}
                            loadOnDemand={""}
                            selectedValue={state.frmData}
                            clrFnct={state.trigger}
                            allwZero={"1"}
                            fnctCall={false}
                            dbCon={"shrtrm"}
                            typr={"790"}
                            dllName={""}
                            fnctName={""}
                            parms={""}
                            allwSrch={true}
                            speaker={"Select City"}

                        ></WtrRsSelect>
                        <WtrRsSelect
                            displayFormat="1"
                            Label="State"
                            fldName="sttid"
                            idText="txtsttid"
                            onChange={onChangeDts}
                            selectedValue={state.frmData}
                            clrFnct={state.trigger}
                            allwZero={"1"}
                            fnctCall={false}
                            dbCon={"shrtrm"}
                            typr={"880"}
                            dllName={""}
                            fnctName={""}
                            parms={""}
                            allwSrch={true}
                            speaker={"Select the State"}
                            delayClose={1000}
                        ></WtrRsSelect>
                        <WtrInput
                            displayFormat="1"
                            Label="Pin Code"
                            fldName="pnc"
                            idText="txtpnc"
                            onChange={onChangeDts}
                            dsabld={false}
                            callFnFocus=""
                            dsbKey={false}
                            maxLenght={6}
                            validateFn="[pincode]"
                            allowNumber={true}
                            ToolTip="Enter numbers only"
                            selectedValue={state.frmData}
                            clrFnct={state.trigger}
                            delayClose={1000}
                            ClssName=""
                            speaker={"Enter Pin Code"}

                        ></WtrInput>

                    </div>

                    <Seperator heading="Additional Detail"></Seperator>

                    <div className=" mt-4 grid grid-cols-2 gap-x-8 gap-y-4">
                        <WtrInput
                            displayFormat="1"
                            Label="Contact Person" ToolTip="Enter contact person name without special charecters"
                            fldName="cntrprs"
                            idText="txtcntrprs"
                            onChange={onChangeDts}
                            dsabld={false}
                            callFnFocus=""
                            dsbKey={false}

                            validateFn="6[length]"
                            allowNumber={false}
                            selectedValue={state.frmData}
                            clrFnct={state.trigger}
                            speaker={"Enter Name of Contact Person"}

                            delayClose={1000}
                            placement="right"
                            blockNumbers={true}
                            ClssName=""
                        ></WtrInput>

                        <WtrInput
                            displayFormat="1"
                            Label="Contact Person Mobile"
                            fldName="mob"
                            idText="txtmob"
                            onChange={onChangeDts}
                            dsabld={false}
                            callFnFocus=""
                            dsbKey={false}

                            validateFn="[mob]"
                            allowNumber={true}
                            ToolTip="Enter numbers only"
                            selectedValue={state.frmData}
                            clrFnct={state.trigger}
                            speaker={"Enter Mobile Number"}

                            delayClose={1000}
                            placement="right"
                            ClssName=""
                        ></WtrInput>
                        <WtrRsSelect
                            displayFormat="1"
                            Label="Entity"
                            fldName="pplid"
                            idText="txtpplid"
                            onChange={onChangeDts}
                            selectedValue={state.frmData}
                            clrFnct={state.trigger}
                            allwZero={"1"}
                            fnctCall={false}
                            dbCon={"shrtrm"}
                            typr={"589"}
                            dllName={""}
                            fnctName={""}
                            parms={""}
                            allwSrch={true}
                            speaker={""}
                            delayClose={1000}
                        ></WtrRsSelect>
                        <WtrInput
                            displayFormat="1"
                            Label="Phone"
                            fldName="phn"
                            idText="txtphn"
                            onChange={onChangeDts}
                            dsabld={false}
                            callFnFocus=""
                            dsbKey={false}
                            validateFn="[mob]"
                            allowNumber={true}
                            ToolTip="Enter numbers only"
                            selectedValue={state.frmData}
                            clrFnct={state.trigger}
                            speaker={"Enter Mobile Number"}
                            delayClose={1000}
                            placement="right"
                            ClssName=""
                        ></WtrInput>
                        <WtrInput
                            displayFormat="1"
                            Label="E Mail"
                            fldName="eml"
                            idText="txteml"
                            onChange={onChangeDts}
                            dsabld={false}
                            callFnFocus=""
                            dsbKey={false}
                            upprCase={false}
                            validateFn="[email]"
                            allowNumber={false}
                            selectedValue={state.frmData}
                            clrFnct={state.trigger}
                            unblockSpecialChars={true}
                            delayClose={1000}
                            speaker={"Enter a valid email address"}
                            placement="right"
                            ClssName=""
                        ></WtrInput>
                        <WtrInput
                            displayFormat="1"
                            Label="Password"
                            fldName="psw"
                            idText="txtpsw"
                            onChange={onChangeDts}
                            dsabld={false}
                            callFnFocus=""
                            dsbKey={false}
                            unblockSpecialChars={true}
                            validateFn="[psw]"
                            ToolTip="Enter Password"
                            selectedValue={state.frmData}
                            clrFnct={state.trigger}
                            speaker={"Enter Password"}
                            delayClose={1000}
                            placement="right"
                            ClssName=""
                        ></WtrInput>
                    </div>

                    {showMessage && showMessage.message.length != 0 ? <div className="relative py-2">
                        <Toaster data={showMessage} className={''}></Toaster>
                    </div> : <></>}
                    <div className="flex py-5">
                        <div className="mr-4">
                            <Button
                                size="medium"
                                style={{ backgroundColor: "#86c6d9" }}
                                variant="contained"
                                color="success"
                                disabled={false}
                                startIcon={<SaveIcon />}
                                onClick={saveClick}>
                                Submit
                            </Button>
                        </div>
                        {hcfLoginId ? <div className="mr-4">
                            <Button
                                size="medium"
                                variant="outlined"
                                color="primary"
                                disabled={false}
                                onClick={navigateToAr}>
                                Go to Annual Report
                            </Button>
                        </div> : <></>}
                    </div>
                </div>
            </div>


        </div>
    );
}; export default React.memo(HCFMaster);