import React, { useEffect, useState, useCallback } from "react";
import { DatePicker } from "rsuite";
import moment, { now } from "moment";
import { Tooltip, Whisper } from "rsuite";
import { TypeAttributes } from "rsuite/esm/@types/common";
import { isReqFld, isValidArray } from "../../utilities/utilities";
import { getFldValue } from "../../Hooks/useGetFldValue";
type NrjRdt = {
  onChange: (data: string) => void;
  Label?: string;
  ClssName?: string;
  idText: string;
  selectedValue?: string;
  fldName: string;
  delayClose?: number;
  placement?: TypeAttributes.Placement;
  speaker?: string;
  format?: string;
  clear?:number;
  displayFormat?: string;
  size?:'lg' | 'md' | 'sm' | 'xs'| undefined;
  IAmRequired?: any;
};
const NrjRsDt = (props: NrjRdt) => {
  const { onChange, selectedValue, fldName } = props;
  const [textDts, setTextDts] = useState<string>("")
  const [value, setValue] = React.useState(new Date())
  const [tooltipOpen, settooltipOpen] = useState(false);
  const size = props.size? props.size:'lg';

  const onChangeText = (value: Date | null, event: any) => {
       
    if (value) {
      let str: string = moment(value).format("DD-MMM-yyyy");
      setTextDts(str)
      setValue(value)
      onChange(fldName + "][" + str);
      
      // console.log(fldName + "][" + str)
    }
    else{
      
      // console.log(value)
      const today = new Date();
      let str: string = moment(today).format("DD-MMM-yyyy");
      setTextDts(str); 
      onChange(fldName + "][" + str);
    }
  };

  const tltpClose = useCallback(() => {
    settooltipOpen(false);
  }, [tooltipOpen]);

  const onBlurr = (event: any) => {
    if (props.speaker) {
      if (props.delayClose) {
        if (!event.target.value) {
          settooltipOpen(true);
        } else {
          settooltipOpen(false);
        }
      }
    }
  };

  const onOpen = () => {
    if (props.delayClose) {
      setTimeout(function() {
        settooltipOpen(false);
      }, props.delayClose);
    }
  };

  useEffect(()=>{
    if (props.clear && props.clear===1){
      setTextDts("01-Apr-2000")
    }
  },[props.clear])

  useEffect(()=>{
    let dt:string = getFldValue(props.selectedValue || '', fldName);
    if(dt){
      let dte : Date = new Date(dt)
      setValue(dte)
      if(!isNaN(Date.parse(dt))){
        setTextDts(dt);
      }
    }
    else if(!isNaN(Date.parse(props.selectedValue||''))){
      setTextDts(props.selectedValue?props.selectedValue : "" );
    }
    else{
      onChangeText(null,null);
    }
  },[props.selectedValue])


  let rqFld : string = "";
  if (props.speaker? props.speaker : "" ){
    rqFld = "*"
  } else {
    if (isValidArray(props.IAmRequired))
    if (isReqFld(props.IAmRequired, props.fldName)){
      rqFld = "*"
    }
  }


  let dsply: string = props.displayFormat ? props.displayFormat : "2";
  const selectedDate = selectedValue ? new Date(Date.parse(selectedValue)) : null;
  
 if (dsply == "1") {
    return (
      <>

        <Whisper
          open={tooltipOpen}
          onClose={tltpClose}
          delayClose={props.delayClose}
          trigger={"none"}
          onOpen={onOpen}
          placement={props.placement}
          speaker={
            <Tooltip style={{ backgroundColor: "red", fontSize: 12 }}>
              {props.speaker}
            </Tooltip>
          }
        >
    <div className="w-full">
  
          {props.Label && <div className="pt-2 pb-3 text-black">
            {props.Label}
          <span className="astrict">{rqFld}</span>
          </div>}
            <DatePicker
              size={size}
              value={textDts ? new Date(Date.parse(textDts)):  new Date()  }
              placeholder={props.Label}
              // value={}
              onChange={onChangeText}
              format= {props.format? props.format : "dd-MMM-yyyy"}
              onBlur={onBlurr}
              oneTap
              style={{width:'100%'}}
            ></DatePicker>
              </div>
        </Whisper>
      

      </>
    )}
  else if(dsply=='2'){
    return (
    <div className="container">
      <div className="relative h-6 ">
        <Whisper
          open={tooltipOpen}
          onClose={tltpClose}
          delayClose={props.delayClose}
          trigger={"none"}
          onOpen={onOpen}
          placement={props.placement}
          speaker={
            <Tooltip style={{ backgroundColor: "red", fontSize: 12 }}>
              {props.speaker}
            </Tooltip>
          }
        >
           

          <div className="mb-2">
          {props.Label && <div className="pt-2 pb-3 text-black">
            {props.Label}
          <span className="astrict text-red-500">{rqFld}</span>
          </div>}
            <DatePicker 
              size={size}
              value={textDts ? new Date(Date.parse(textDts)): new Date()  }
              placeholder={props.Label}
              onChange={onChangeText}
              format= {props.format? props.format : "dd-MMM-yyyy"}
              onBlur={onBlurr}
              oneTap
              style={{ width: 300 }}
            ></DatePicker>
          </div>
        </Whisper>
      </div>
    </div>
  )};
};
export default NrjRsDt;
