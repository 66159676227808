import { useEffect, useState } from "react";
type Tst = {
  data : any,
  className?: string
}
export const Toaster = (props: Tst) => {
  let cls : string = props.className ? props.className : ""
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [className, setCalssName] = useState<string>(
    "border rounded-lg flex justify-center align-center pt-4 pb-2 " + cls
  );
  useEffect(() => {
    if (props.data && props.data.message.length >0) {
      setShowMessage(true);
    }
  }, [props.data]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, [showMessage]);

  return (
    <>
      {showMessage && props.data &&
        props.data.message.length  &&
        props.data.type == "error" && (
          <div
            className={"border-red-600 text-red-800" + className}
            style={{
              textAlign: "center",
              zIndex: "1001",
              backgroundColor: "rgba(255, 0, 0, 0.3)",
            }}
          >
            
              <ul className="">
                {props.data.message.map((res: any, index: number) => {
                  return (
                    <>
                      <li key={res} className="text-red-500">
                        {index + 1} : {res}
                      </li>
                    </>
                  );
                })}
              </ul>
          </div>
        )}
      {showMessage &&
        (props.data.message.length || props.data.message.message) &&
        props.data.type == "success" && (
          <div
            className={"border-green-600 text-black font-semibold" + className}
            style={{
              backgroundColor: "rgba(0, 255, 0, 0.3)",
              zIndex: "1001",
              textAlign: "center",
            }}
          >
            
              <ul className="">
                {props.data.message.map((res: any, index: number) => {
                  return (
                    <>
                      <li key={res}>
                        {index + 1} : {res}
                      </li>
                    </>
                  );
                })}
              </ul>
          </div>
        )}
    </>
  );
};
