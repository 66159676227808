import React, { useEffect } from 'react'
import { useState } from 'react';
import { Outlet } from 'react-router';
import { useEffectOnce } from 'react-use';
import Sidebar from '../app/vb/sidebar';
import Topbar from '../app/vb/topbar';

import { getApplicationVersion } from '../utilities/utilities';
import { getLvl } from '../utilities/cpcb';

const SwitchLayout = () => {
    const [pageTitle, setPageTitles] = useState("Dashboard")
    const applicationOne: string = getApplicationVersion();
    const hideInState = getLvl() == 'STT' ? true : false;
    const hideInRgd = getLvl() == 'RGD' ? true : false;

    const dropdownOne = [
        { name: 'Live Waste Bag Collection', hide: true, path: "/trckbmw", description: "Real Time Waste Collection (From Midnight)" },
        { name: 'CBWTF List', hide: false, path: "/cbwtfdspl", description: "COMMON BIOMEDICAL WASTE TREATMENT FACITLIY" },
        { name: 'CBWTF Info', hide: false, path: "/displayDataCard", description: "CBWTF Information" },
        { name: 'HCF List', hide: false, path: "/hcflstgrd", description: "List of Health Care Facility" },
        { name: 'HCF - CBWTF', hide: false, path: "/hcfcbwtf", description: "Common Biomedical Waste Facility" },
        { name: 'Waste Bags', hide: false, path: "/wstbgs", description: "List of Waste Bags" },
        { name: 'List of Wrong HCF code', hide: false, path: "/ListWrngHCFCode", description: "List of Wrong HCF Code" },
        { name: 'CBWTF Daily Report', hide: false, path: "/cbwtfdlyrep", description: "Daily Report of CBWTF's" },
        // { name: 'Annual Form (CBWTF)', hide: false, path: "/allAnnlRpt", description: "Annual Report : CBWTF's" },
        // { name: 'State Annual Report', hide: hideInState, path: "/allStateReport", description: "State Annual Reports" },
        // { name: 'Annual Report', hide: false, path: "/annualRepA", description: "" },
// { name: 'Bag Count Dashboard', hide: false, path: "/bagCntChrt", description: "Bag count from bedded / Non bedded Hospital" },
        { name: 'Bag Weight Dashboard ', hide: true, path: "/bagWtChrt", description: "Bag weight from bedded / Non bedded Hospital" },
        // { name: 'Hcf Dashboard', hide: false, path: "/bagHcfChrt", description: "Hcf Dashboard" },
    ]

    const dropdownChart = [
        { name: 'Bags distribution wrt Scan By - Pie Chart', hide: false, path: "/bagWtCntChrt10", description: "Bags Distribution Based On Date (Form Date - To Date)" },
        { name: 'Scan by Value', hide: true, path: "/bagWtCntChrt", description: "View Details Based On Scan Values" },
        { name: 'Bags distribution wrt Hospitals (Below/Above 30 Beds) - Pie Chart', hide: false, path: "/bagWtCntChrt4", description: "Bags Distribution Below/Above 30 HCF Based On Scan Values" },
        { name: 'Bags distribution wrt Distance (Below/Above 50 Mtr)  - Pie Chart', hide: false, path: "/bagWtCntChrt5", description: "Bags Distribution Based On Distance Below/Above 50 Meters Based On Scan Values" },
        { name: 'Bags distribution wrt Hospitals (Below/Above 30 Beds)', hide: false, path: "/bagWtCntChrt2", description: "Explore Bags List Below/Above 30 Beds  Based On Scan Values" },
        { name: 'Bags distribution wrt Distance (Below/Above 50 Mtr)', hide: false, path: "/bagWtCntChrt3", description: "Check The List Of Bags Based On Distance Below/Above 50 Meters  Based On Scan Values" },
        { name: 'Bags distribution wrt Distance (Below/Above 50 Mtr) /(Below 30 Beds) - Pie Chart', hide: false, path: "/bagWtCntChrt6", description: "Bags Distribution Based On Distance Below/Above 50 Meters Based Below 30 Beds)" },
        { name: 'Bags distribution wrt Distance (Below/Above 50 Mtr) /(Above 30 Beds) - Pie Chart', hide: false, path: "/bagWtCntChrt7", description: "Bags Distribution Based On Distance Below/Above 50 Meters Based On Above 30 Beds" },
        { name: 'Bags distribution wrt Distance (Below/Above 50 Mtr) /(Below 30 Beds) - Bar Chart', hide: false, path: "/bagWtCntChrt8", description: "Bags Distribution Based On Distance Below/Above 50 Meters Based Below 30 Beds)" },
        { name: 'Bags distribution wrt Distance (Below/Above 50 Mtr) /(Above 30 Beds) - Bar Chart', hide: false, path: "/bagWtCntChrt9", description: "Bags Distribution Based On Distance Below/Above 50 Meters Based On Above 30 Beds" },


    ];


    const dropdownTwo = [
        { name: 'CPCB', hide: hideInState, path: "/cntr", description: "Details of Head Office" },
        { name: 'RD', hide: hideInState, path: "/rgndlst", description: "List of Regional Directorate" },
        { name: 'SPCB', hide: hideInState, path: "/sttdlst", description: "List Of State Pollution Board" },
        { name: 'All State Boards', hide: hideInState, path: "/sttall", description: "All State Boards" }
    ]

    const dropdownThree = [
        { name: 'HCF Category Count (Chart View)', hide: false, path: "/hcfCtgCnt", description: "HCF Category Count " },
        { name: 'HCF Category Count', hide: false, path: "/hcfCtgGrid", description: "HCF Category Count " },
        //{ name: 'HCF Visited', hide: false, path: "/hcfNmbrVisited", description: "Count of Visited HCF" },
        { name: 'HCF Visited/Non Visited', hide: false, path: "/hcfNonVisited", description: "Count of HCF's Visited/Non-Visited" },
        // { name: 'HCF Not Visited', hide: false, path: "/hcfnotvst", description: "List of Not Visited HCF" },
        { name: 'Bag Count Per Hour (Chart View)', hide: false, path: "/bagCntPrHr", description: "Bag Count Per Hour" },
        { name: 'Bag Count Per Hour', hide: false, path: "/bagCntPrGrid", description: "Bag Count Per Hour" },
        { name: 'Bags Above 30 Kg', hide: false, path: "/bigBag", description: "List of Waste Bag above 30 Kg" },
        { name: 'Waste Bag GeoLocation', hide: false, path: "/bagcntwthGeo", description: "Summary of Waste Bags with and without Location Data" },
        { name: 'Waste Bag Serial Number', hide: false, path: "/serialNumber", description: "Summary of Waste Bags With and Without Serial Number" },
        { name: 'Waste Bag Label No', hide: false, path: "/bagcntwthLbl", description: "Summary of Waste Bag with and without Label Count" },
        { name: 'Waste Bag Odd Time Bags', hide: false, path: "/WstbgOdd", description: "Waste Bag Received At Odd Time i.e. From Midnight To 8 AM  and After 8 PM" },
        // { name: 'Hcf Acknowledgment', hide: false, path: "/acknowledgmentHcf", description: "Count of Hcf who's Acknowledgment Recevied And Not Recevied" },


        //{ name: 'Monthly HCF Status', hide: false, path: "/monthlyStatus", description: "Monthly status of Health Care Facility" },
    ]

    const dropdownFour = [
        { name: 'Acivity on Portal', hide: false, path: "/actvrpt", description: "Activity on the Biomedical Waste Portal" },
        { name: "CBWTF's Daily Bags Count", hide: false, path: '/bagCbwtf', description: "CBWTF's Daily Bags Count" },
        { name: "HCF's Daily Bags Count", hide: false, path: '/bagCbwtfHcf', description: "Hcf's Daily Bags Count" },

        { name: "CBWTF's Daily Bags Cnt/Scan By", hide: false, path: '/bagCbwtfScnBy', description: "CBWTF's Daily Bags Count As per Scan By Values" },
        { name: "CBWTF's at Factory", hide: false, path: '/bagFactory', description: "CBWTF's Daily Bags Count At Factory Level" },
        { name: 'map/India', hide: hideInRgd || hideInState, path: '/mapIndia', description: " Heat Map of India" },
        { name: 'Bedded Approximately  Ratio', hide: false, path: '/beddedRatio', description: "Ratio with respect to actual and expected value" },
    ]

    const dropdownFive = [
        { name: 'Search HCF', hide: false, path: "/cityHcfSearch", description: "Search HCF in City" },
        { name: 'Waste Bag', hide: false, path: "/hcf_wstbg", description: "Search Waste Bags" },
        { name: 'Find HCF', hide: false, path: "/Find_HCF", description: "Search HCF" },
        { name: 'Waste Bag By ID', hide: false, path: "/wstbgid", description: "Search Waste Bag By ID" },

        { name: 'Search Health Care Facility', hide: false, path: "/srch_hcf", description: "Health Care Facility" },
        { name: 'Count HCF', hide: hideInState, path: "/rgdHcfSearch", description: " HCF in Regional Directorate" },
        // { name: 'PNU ', hide: false, path: "/hcfWasteData", description: "Count of HCF visited by cbwtf" },
    ]
    const dropdownSix = [
        { name: 'Daily Report: Detailed', hide: false, path: "/hcf_wstbg", description: "Waste Collection Report: Daily Detailed" },
        { name: 'Daily Report: Summary', hide: false, path: "/dailysummary", description: "Waste Collection Report: Daily Summary" },
        { name: 'Daily Report: Route Wise', hide: false, path: "/dailySummaryRoute", description: "Daily Summary: Route Wise" },
        { name: 'Daily Summary: Factory (Comparison)', hide: false, path: "/dailysummaryfactoryComp", description: "Waste Bags Scanned at HCF / Factory (Comparison)" },

        { name: 'Daily Summary: Factory', hide: false, path: "/dailysummaryfactory", description: "Waste Bags Scanned at Factory" },
        { name: 'Daily Summary: Factory Not Scan', hide: false, path: "/dailysummaryfactorynotscn", description: "Waste Bags not scanned at Factory" },
    ]
    const otherpage = [
        { path: "/spcb_frst", description: "State Annual Report: Details" },
        { path: "/spcb_cbwtf", description: "State Annual Report: CBWTF" },
        { path: "/spcb_auth", description: "State Annual Report: Authorize " },
        { path: "/spcb_dstr", description: "State Annual Report:  District " },
        { path: "/spcb_hcfcptv", description: "State Annual Report: HCF Captive " },
        { path: "/annlWstWt", description: "Annual Report Form" },
        { path: "/annlWstStrg", description: "Annual Report Form" },
        { path: "/annlEqp", description: "Annual Report Form" },
        { path: "/annlMisc", description: "Annual Report Form" },
    ]



    useEffectOnce(() => {

        let pathName = window.location.pathname;
        console.log(pathName)
        dropdownOne.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })

        dropdownChart.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })

        dropdownTwo.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })

        dropdownThree.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })

        dropdownFour.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })
        dropdownSix.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })
        otherpage.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })

    })

    const [showSideNav, setShowSideNav] = useState(false)

    const toggleSideNavOff = () => {
        setShowSideNav(false)
    }

    const toggleSideNavOn = () => {
        setShowSideNav(true)
    }

    useEffect(() => {
        console.log('SwitchLayout called');
    }, [])

    return (
        <>
            {applicationOne === '2' ? <div className={`${showSideNav ? 'grid grid-cols-9' : ""} `}>
                <div className={`${showSideNav ? 'col-span-2' : "hidden"}`}>
                    <div className='bg-white h-screen overflow-y-auto mr-2'>
                        <Sidebar toggleSideNavOff={toggleSideNavOff} setPageTitle={setPageTitles} dropdownOne={dropdownOne} dropdownChart={dropdownChart} dropdownTwo={dropdownTwo} dropdownThree={dropdownThree} dropdownFour={dropdownFour} dropdownFive={dropdownFive} dropdownSix={dropdownSix}></Sidebar>
                    </div>
                </div>
                <div className="col-span-7 h-screen overflow-y-auto ml-2">
                    <div className='sticky top-1 z-10 mr-2'>
                        <Topbar toggleSideNavOn={toggleSideNavOn} toggleSideNavOff={toggleSideNavOff} showSideNav={showSideNav} title={pageTitle} />
                    </div>
                    <div className='pb-12'>
                        <div className='mr-2'>
                            <Outlet />
                        </div>
                    </div>


                </div>
            </div> :
                <div>
                    <Outlet />
                </div>
            }
        </>
    )
}

export default React.memo(SwitchLayout)
