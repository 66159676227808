import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { usePrevious } from "react-use";
import utilities, { createGetApi, dataStr_ToArray, getCmpId, getUsrId, mdf } from "../../utilities/utilities";
import { AgGridReact } from "ag-grid-react";
import { nrjAxios, nrjAxiosRequest } from "../../Hooks/useNrjAxios";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "./NrjAgGrid.css";
import NrjAgBtn from "./NrjAgBtn";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Button, Tooltip } from "@mui/material";
import { Modal } from "rsuite";

type AgGrid = {
  ratio?: { maxNumber: number, minNumber: number, equalto: number, color: string }[];
  rowClassRulesValues?: { numerator: any; denominator: any; } | undefined;
  cellClassRulesValues?: { color: string; cellName: any; value?: any; colorEntireRow?: boolean }[];

  colDef: {
    headerName: string;
    field?: string;
    width?: number;
    hidden?: boolean;
    cellStyle?: {};
    editable?: boolean;
    children?: any[];
    headerTooltip?: any;
    tooltipField?: any;
    cellClass?: any
  }[];
  rowData: any[];
  apiCall: string;
  onRowSelected: (data: string) => void;
  trigger?: number | 0;
  newRowData?: {}[];
  onGridAnswers?: (data: []) => void;
  askGrid?: string;
  onGridLoaded: () => void;
  deleteButton?: string;
  showDataButton?: string;
  showApi?: string;
  showFldNm?: string;
  deleteApi?: string;
  deleteFldNm?: string;
  queryOnLoad?: boolean;
  queryRefreshTime?: number;
  queryStaleTime?: number;
  addButton?: string;
  addButtonApi?: string;
  addFldNm?: string;
  height?: number;
  width?: string;
  fontSize?: number;
  showPagination?: boolean;
  PageSize?: number;
  StaleTime?: number;
  onCellEdited?: (
    fld: string,
    rowdata: any,
    oldValue: string,
    newValue: string
  ) => void;
  newRowOnTop?: boolean;
  className?: string;
  rowHeight?: number;
  onCellClicked?: any;
  onButtonClicked?: (columnName: string, rowData: any) => void;
  triggerText?: string;
  paginationChanged?: any;
  ApiServer?: string;
  deleteId?: string;
  parentPaginationChanged?: (data: any) => void;
  showTooltips?: boolean
  NoRecordEachCall?: number
  MyRoute?: string,
  appName?: string,
  getFilteredParameter?:any,
  hideSerialNo? :boolean,
  widthSerialNoCol? : number,
  updateRowData?: any
};

const NrjAgGrid = (props: AgGrid) => {
  const {
    onRowSelected,
    onGridAnswers,
    onGridLoaded,
    onCellEdited,
    onButtonClicked,
    parentPaginationChanged,
  } = props;
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState(props.rowData);
  const [allData, setAllData] = useState<any>([]);
  const [gridApi, setGridApi] = useState(props.apiCall);
  const [wchApi, setWchApi] = useState(0);
  const prvApi = usePrevious(gridApi);
  const navigate = useNavigate();
  const [countTillNow, setCountTillNow] = useState(0);
  const [actvPage, setActvPage] = useState(0);
  const [colDefSeq, setColDefSeq] = useState<any[]>([])

  const serialNoGetter = (params:any) => {
    return params.node ? params.node.rowIndex+1 : null;
  };

  const serialNo:any = {
    headerName: 'Serial No',
    maxWidth: props.widthSerialNoCol?props.width : 70,
    valueGetter: serialNoGetter,
    hide: props.hideSerialNo? true : false, 
    filter: true
  };

  const [colDef, setColdef] = useState([serialNo, ...props.colDef?.map((res: any) => {
    return {
      ...res, filter: true
    }
  })]);
  const [fixedColDef, setFixedColdef] = useState([...colDef]);
    const [orderedColDef, setOrderedColdef] = useState([...colDef]);

  useEffect(()=>{
     
    if(!props.MyRoute){
      const tempColDef:any[] = [serialNo, ...props.colDef?.map((res: any) => {
        return {
          ...res, filter: true
        }
      })]
        setColdef(tempColDef);
        setFixedColdef(tempColDef);
        setOrderedColdef(tempColDef);
    }
  },[props.colDef])
    

  const grdSty = {
    height: props.height ? props.height : 500,
    width: props.width ? props.width : "100%",
    fontsize: props.fontSize ? props.fontSize : 18,
  };

  const defaultColDef = {
    sortable: true,

  };

  useEffect(() => {
    if (props.triggerText == "preserve" && rowData.length) {
      setAllData(rowData);
    } else if (props.triggerText == "show") {
      if (allData && allData.length > 0) {
        setRowData(allData);
      }
    }
  }, [props.triggerText]);

  const addDelButton = (parms: string) => {
    let ttl: string[] = parms.split("|");
    let cdef: {};
    if (ttl && ttl.length > 2) {
      cdef = {
        field: ttl[2],
        headerName: ttl[0],
        width: 250,
        hide: false,

        cellRenderer: NrjAgBtn,
        cellRendererParams: {
          deleteApi: ttl[1],
          buttonText: ttl[0],
          fldName: ttl[2],
          colId: "delete",
        },
      };
      return cdef;
    }
  };

  useEffect(() => {
    if (props.rowData?.length) {
        setRowData(props.rowData);
    }
  }, [props.rowData]);

  useEffect(() => {
    if (props.newRowData?.length && Array.isArray(props.newRowData)) {
      let ontop: boolean = props.newRowOnTop ? props.newRowOnTop : false;
      if (ontop) {
        if (rowData && rowData.length > 0) {
          setRowData(props.newRowData.concat(rowData));
        } else {
          setRowData(props.newRowData);
        }
      } else {
        if (rowData && rowData.length > 0) {
          setRowData(rowData.concat(props.newRowData));
        } else {
          setRowData(props.newRowData);
        }
      }
      onGridLoaded();
    }
  }, [props.newRowData]);


  useEffect(() => {
    if(props.updateRowData && props.updateRowData.id != undefined){
      setRowData(rowData.map((res:any)=>{
          
        if(props.updateRowData.id == res.id){
          return props.updateRowData;
        }
        else{
          return res;
        }
      }))
    }
  }, [props.updateRowData]);

  



  /****************************************************************************************************** */

  const addShowButton = (parms: string) => {
    let ttl: string[] = parms.split("|");
    let cdef: {};
    if (ttl && ttl.length > 2) {
      cdef = {
        field: ttl[2],
        headerName: ttl[0],
        width: 200,
        hide: false,

        cellRenderer: NrjAgBtn,
        cellRendererParams: {
          showApi: ttl[1],
          buttonText: ttl[0],
          fldName: ttl[2],
          colId: "show",
        },
      };
      return cdef;
    }
  };

  const CallData = () => {
    let api: string = "";
    if (gridApi.indexOf("[lastid]") > -1) {
      api = gridApi.replace("[lastid]", `${countTillNow}`);
    } else {
      api = gridApi;
    }
    let wch: number = wchApi;
    if (wch > 0) {
      api = "";
      if (props.deleteApi) {
        api = props.deleteApi;
        api = api.replace("=id=", "=" + wch + "=");
      }
    }

    let cmpid: string = getCmpId() || "1";
    api = api.replace("cmpid", cmpid);
    cmpid = getUsrId() || "1";
    api = api.replace("usrid", cmpid);
    api = mdf(api);
    api = "/api/GetFldValue/" + api;
    return nrjAxios({ apiCall: api, whchServer: props.ApiServer });
  };
  const grd = (dta: any) => {
    let wch = wchApi;
    if (wch) {
      setWchApi(0);
      return;
    }
    if (dta) {
      let dt: string = dta.data[0]["Data"];
      if (dt) {
        data_grd(dt);
      }
    }
  };
  const { data, refetch, isLoading } = useQuery({
    queryKey: ["ListLink"],
    queryFn: CallData,
    enabled: props.queryOnLoad ? props.queryOnLoad : false,
    staleTime: props.StaleTime ? props.StaleTime : Number.POSITIVE_INFINITY,
    onSuccess: grd,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const onGridReady = useCallback(() => {
    if (gridApi) {
      if (!props.queryOnLoad) {
        refetch();
      }
    }
  }, [0]);

  const data_grd = (datas: string) => {
    let dat = datas;
    if (dat) {
      let ary: any = dataStr_ToArray(dat);
      if (ary) {
        setCountTillNow(countTillNow + ary.length);
        setRowData(rowData.concat(ary));
      }
    }
  };

  const onSelectionChanged = () => {
    var selectedRows = gridRef.current!.api.getSelectedRows();
    var selectedRowsString = "";
    var maxToShow = 1;
    selectedRows.forEach(function (selectedRow: any, index: any) {
      if (index >= maxToShow) {
        return;
      }
      for (let i = 0, j = props.colDef.length; i < j; i++) {
        if (selectedRow[props.colDef[i].field || 0]) {
          selectedRowsString += selectedRow[props.colDef[i].field || 0] + "|";
        } else {
          selectedRowsString += "|";
        }
      }
      onRowSelected(selectedRowsString);
    });
  };

  useEffect(() => {
    if (props.trigger) {
      if (props.trigger == 2) {
        if (props.askGrid) {
        }
      } else if (props.trigger == 3) {
        // Recall api for data of the grid
        if (props.apiCall) {
          setGridApi(props.apiCall);

          setTimeout(function () {
            if (wchApi === 0 && gridApi) {
              refetch();
            }
          }, 500);
        }
      } else if (props.trigger === 4) {
        //Download CSV Export
        gridRef.current!.api.exportDataAsCsv();
      } else if (props.trigger === 5) {

        //Download CSV Export
        setRowData([]);
      } else if (props.trigger === 6) {
        if (props.rowData) {
          setRowData(props.rowData);
        }
      } else if (props.trigger === 7) {
        if (props.deleteId ? props.deleteId : null) {
          let sliceIndex = -1;

          for (var i = 0; i < rowData.length; i++) {
            if (rowData[i]["id"] === props.deleteId) {
              sliceIndex = i;
              break;
            }
          }
          if (sliceIndex != -1) {
            setRowData(rowData.splice(sliceIndex, 1));
          }
        }
      }
    }
  }, [props.trigger, gridApi]);

  let blnAdd: boolean = false;
  if (props.deleteButton) {
    for (var i = 0, j = props.colDef.length; i < j; i++) {
      if (props.colDef[i]["headerName"] === props.deleteButton) {
        blnAdd = true;
        break;
      }
    }
    if (!blnAdd) {
      let cl: any = addDelButton(
        props.deleteButton + "|" + props.deleteApi + "|" + props.deleteFldNm
      );
      props.colDef.push(cl);
    }
  }

  blnAdd = false;
  if (props.showDataButton) {
    for (var i = 0, j = props.colDef.length; i < j; i++) {
      if (props.colDef[i]["headerName"] === props.showDataButton) {
        blnAdd = true;
        break;
      }
    }

    if (!blnAdd) {
      let cl: any = addShowButton(
        props.showDataButton + "|" + props.showApi + "|" + props.showFldNm
      );
      props.colDef.push(cl);
    }
  }

  const updateSerialNo = ()=>{
    let flag:boolean = true;
    for(let res of colDef){
      if(res.headerName == 'Serial No'){
        flag = false;
        break;
      }
    }
      let serialNo:any = {
        headerName: 'Serial No',
        maxWidth: props.widthSerialNoCol?props.width : 70,
        valueGetter: serialNoGetter,
      };
      if(!flag){
        let tempColdef: any[] = [...colDef]
        tempColdef.splice(0,1);
        setColdef([serialNo, ...tempColdef]);
      }
      else{
        setColdef([serialNo, ...colDef]);
      }
      
  }

  

  const onCellClicked = (params: any) => {
    let curFldDel: string = "";
    let cellclk: string = params["colDef"]["headerName"];
    let fieldName: string = params['colDef']["field"];
    let intWch: number = 0;
    if (props.deleteButton) {
      if (cellclk === props.deleteButton) {
        curFldDel = cellclk;
        intWch = 1;
      }
    }

    if (!curFldDel) {
      if (props.showDataButton) {
        if (cellclk === props.showDataButton) {
          curFldDel = cellclk;
          intWch = 2;
          if (onButtonClicked) {
            onButtonClicked(cellclk, rowData[params.rowIndex]);
          }
        }
      }
    }
    let vl: string = props.showApi ? props.showApi : "";
    let ary: any;
    if (intWch == 0) {
      if (vl) {
        if (vl.indexOf("key][") > -1) {
          ary = dataStr_ToArray(vl);
          let i: number = 0;
          while (i < ary.length) {
            if (ary[i]["cellclicked"]) {
              if (ary[i]["cellclicked"] == cellclk) {
                intWch = 2;
                break;
              }
            }
            i += 1;
          }
        }
      }
      else if(onButtonClicked) {
        onButtonClicked(cellclk, rowData[params.rowIndex]);
      }
    }
    if (intWch === 1) {
      let rw: any = rowData;
      let rwid: any = rowData[params.rowIndex];

      let rwIId: number = parseInt(rwid["id"]);
      setWchApi(rwIId);
      if (onButtonClicked) {
        onButtonClicked(cellclk, rowData[params.rowIndex]);
      }
      if (props.apiCall) {
        rw.splice(params.rowIndex, 1);
        setRowData([]);
        setTimeout(function () {
          setRowData(rowData);
          refetch();
        }, 300);
      }
    } else if (intWch === 2) {
      let rw: any = rowData[params.rowIndex];
      if (onButtonClicked) {
        onButtonClicked(cellclk, rowData[params.rowIndex]);
      }

      if (vl) {
        if (vl.indexOf("key][") > -1) {
          //sample keyname][set this value to name of session item = key][Value to set = path][path for navigation
          //optional cellclicked][headername
          let i: number = 0;
          while (i < ary.length) {
            if (ary[i]["cellclicked"]) {
              if (ary[i]["cellclicked"] == cellclk) {
                if (ary[i]["keyname"] && ary[i]["key"]) {
                  if (ary[i]["keyname"].indexOf('>') > -1 && ary[i]["key"].indexOf('>') > -1) {
                    // console.log(ary[i]["key"]);

                    let twoDataKeyName: string[] = ary[i]["keyname"].split(">");
                    let twoDataKey: string[] = ary[i]["key"].split(">");
                    twoDataKeyName.forEach((res: any, index: number) => {
                      twoDataKey[index] == "field"
                        ? sessionStorage.setItem(
                          twoDataKeyName[index],
                          fieldName
                        )
                        : sessionStorage.setItem(
                          twoDataKeyName[index],
                          rw[twoDataKey[index]]
                        );
                    });
                  } else {
                    sessionStorage.setItem(
                      ary[i]["keyname"],
                      rw[ary[i]["key"]]
                    );
                  }
                  if (ary[i]["path"]) {
                    navigate("/" + ary[i]["path"]);
                    break;
                  }
                }
              }
            }
            i += 1;
          }

        } else {
          let ech: string[] = vl.split("|");
          if (ech && ech.length > 2 && ech[1]) {
            let keyVl: any = rw[ech[1]];
            if (keyVl) {
              sessionStorage.setItem(ech[0], keyVl);
              if (ech[2]) {
                navigate("/" + ech[2]);
              }
            }
          }
        }
      }
    }
  };

  const onSortChanged = (parms: any) => {
    if (props.hideSerialNo ? props.hideSerialNo : false) {
      return;
    }
    updateSerialNo();
  }


  const CellEditingStopped = (event: any) => {
    if (event.valueChanged) {
      if (props.onCellEdited) {
        let ov: string = "";
        if (event.oldValue) {
          ov = event.oldValue;
        }

        let nv: string = "";
        if (event.newValue) {
          nv = event.newValue;
        }
        props.onCellEdited(event.colDef["field"], event.data, ov, nv);
      }
    }
  };

  const [prevTotalPages, setPrevTotalPages] = useState(0);
  const paginationChanged = (data: any) => {
    if (props.apiCall?.length > 0) {
      let curPg: number = data.api.paginationProxy.currentPage;
      let pgCnt: number = data.api.paginationProxy.totalPages;
      if (
        data.api.paginationProxy.totalPages - prevTotalPages > 0 &&
        data.api.paginationProxy.totalPages -
        data.api.paginationProxy.currentPage <=
        1
      ) {
        // console.log("pagination api called");
        if (parentPaginationChanged) {
          parentPaginationChanged(data)
          // console.log(data)
        }
        // console.log(data)
        setPrevTotalPages(data.api.paginationProxy.totalPages);
        setActvPage(curPg + 1);
        // refetch();
      }
    } else {
      if (parentPaginationChanged) {

        parentPaginationChanged(data)
      }

    }
  };


  const onBodyScroll = (data: any) => {

  }


  const cellclassValue = props.cellClassRulesValues || [];



  colDef.forEach((col) => {
    const cellClassFunctions: any = [];
    for (const c of cellclassValue) {
      if (c.colorEntireRow) {
        cellClassFunctions.push((params: any) => {
          return params.data[c.cellName] === c.value ? c.color : '';
        });
      } else {
        if (col.field === c.cellName) {
          cellClassFunctions.push((params: any) => {
            return params.data[c.cellName] === c.value ? c.color : '';
          });
        }
      }
      if (c.value === '' || c.value === null || c.value === undefined) {
        if (col.field === c.cellName) {
          cellClassFunctions.push((params: any) => {
            return params.data[c.cellName] ? c.color : '';
          });
        }
      }
    }
    if (cellClassFunctions.length > 0) {
      col.cellClass = (params: any) => {
        for (const cellClassFunction of cellClassFunctions) {
          const cellClass = cellClassFunction(params);
          if (cellClass) {
            return cellClass;
          }
        }
        return '';
      };
    }
  });

  const numerator = props.rowClassRulesValues?.numerator;
  const denominator = props.rowClassRulesValues?.denominator;
  const ratio = props.ratio || [];

  const rowClassRules: any = {};
  for (const r of ratio) {
    rowClassRules[r.color] = (params: any) => {
      const num = params.data[numerator];
      const denom = params.data[denominator];
      if (denom !== 0) {
        let ratioof: number = 0;
        ratioof = Number(num) / Number(denom);
        if (
          ratioof === r.equalto ||
          (ratioof < r.maxNumber && ratioof > r.minNumber)
        ) {
          return r.color;
        }
      }
      return '';
    };
  }

  const getGid = () => {
      let g: any = utilities(3, "", "");
      return g;
  };

  const [open, setOpen] = useState(false);
  const [stabiliser, setStabiliser] = useState<boolean>(true);
  const [modelData, setModelData] = useState<any>({});

  const handleOpen = (data: any) => {
    if (stabiliser) {
      setOpen(true);
      setModelData(data);
      setStabiliser(!stabiliser);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setStabiliser(!stabiliser);
  };

  const resetColDef = ()=>{
    let tempColDef:any[] = [serialNo, ...props.colDef?.map((res: any) => {
      return {
        ...res, filter: true
      }
    })]
    setColdef(tempColDef);
    setFixedColdef(tempColDef)
    setOrderedColdef(tempColDef);
    let seq = "";
    props.colDef.forEach((res:any)=>{
      if(seq){
        seq += ','
      }
      seq += res.field;
    })

    sendColDefSeq(seq).then((res:any)=>{
      console.log("successfully saved sequence");
      res = dataStr_ToArray(res);
      setColDefSeq(res);
    }).catch(((res:any)=>{console.log(res)}))
    handleClose();
  }

  useEffect(()=>{
    if(props.MyRoute && props.appName){
      fetchColdefSeq();
    }
  },[props.MyRoute, props.appName])

  const fetchSeq = ()=>{
    let api = createGetApi("db=nodb|dll=s|fnct=c75", `${props.appName}=${props.MyRoute}`);
      return nrjAxios({ apiCall: api });
  }

  const fetchSeqSuccess = (coldef: any)=>{
      if(coldef && coldef?.data[0]?.Data){
        let sortOrder:any = dataStr_ToArray(coldef.data[0].Data);

        if(sortOrder && Array.isArray(sortOrder) && sortOrder.length){
          sortOrder = sortOrder[0].cols? sortOrder[0].cols.split(','):[];
          let sortedArrayDeleted:any[] = []
         
          sortedArrayDeleted = sortArrayByFieldOrderDeleted(orderedColDef, sortOrder)
          setColdef(sortedArrayDeleted);
          setOrderedColdef(sortedArrayDeleted);

          let sortedArray = sortArrayByFieldOrder(orderedColDef, sortOrder);
          sortedArray = sortedArray.map((res:any)=>{
            let flag = false;
            for(let i = 0; i < sortedArrayDeleted.length; i++){
              if(res.field == sortedArrayDeleted[i].field){
                flag = true;
                break;
              }
            }
            if(!flag){
              return {...res, filter:false}
            }
            else{
              return res;
            }

          })
          
          setFixedColdef(sortedArray); 
        }
      }    
  }

  const { data: coldef, refetch: fetchColdefSeq } = useQuery({
    queryKey: ["fetchcolDefSeq", props.MyRoute],
    queryFn: fetchSeq,
    enabled: false,
    staleTime: Number.POSITIVE_INFINITY,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: fetchSeqSuccess,
  });

  const sendColDefSeq = (data:any) => {
    let dts:string = `route][${props.MyRoute}=cols][${data}=app][${props.appName}=flnme][cmn0grdstn=dll][s=fnct][c74`
    let body = { 
      gid: getGid(),
      cmp: getCmpId(),
      usr: getUsrId(),
      dts: dts,
    };

    return nrjAxiosRequest("svfrm", body);
  };

  const changeColdef = (data: any) => {
    let tempList:any[] = [];
    setFixedColdef(fixedColDef.map((res: any) => {
      if (res.field == data) {
        if(!res.filter){
          tempList.push(res);
        }
        return {
          ...res, filter: !res.filter
        }
      }
      if(res.filter){
        tempList.push(res);
      }
      
      return res;
    }))

    setOrderedColdef(tempList);
  }

  const applyChanges = () => {
    setColdef(orderedColDef);
    let seq:string = "";
    orderedColDef.forEach((res:any)=>{
      if(seq){
        seq += ','
      }
      seq += res.field;
    })
    sendColDefSeq(seq).then((res:any)=>{
      console.log("successfully saved sequence");
      res = dataStr_ToArray(res);
      setColDefSeq(res);
    }).catch(((res:any)=>{console.log(res)}))
    handleClose();
  }

  const [sourceElement, setSourceElement] = useState<any>({})

  const handleDragStart = (event: any) => {
    event.target.style.opacity = 0.5
    setSourceElement(event.target);
    event.dataTransfer.effectAllowed = 'move'
  }

  /* do not trigger default event of item while passing (e.g. a link) */
  const handleDragOver = (event: any) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }

  const handleDragEnter = (event: any) => {
    event.target.classList.add('over')
  }

  const handleDragLeave = (event: any) => {
    event.target.classList.remove('over')
  }

  const handleDrop = (event: any) => {
    /* prevent redirect in some browsers*/
    event.stopPropagation()

    /* only do something if the dropped on item is 
    different to the dragged item*/
    if (sourceElement !== event.target) {

      /* remove dragged item from list */
      const list = orderedColDef.filter((item, i) => {
        return item.headerName !== sourceElement.outerText
      })

      /* this is the removed item */
      const removed = orderedColDef.filter((item, i) =>
        item.headerName === sourceElement.outerText)[0]
      /* insert removed item after this number. */
      let insertAt = Number(event.target.id)
      let tempList = []

      /* if dropped at last item, don't increase target id by +1. 
         max-index is arr.length */
      if (insertAt >= list.length) {
        tempList = list.slice(0).concat(removed)
        setOrderedColdef(tempList)
        event.target.classList.remove('over')
      } else
        if ((insertAt < list.length)) {
          /* original list without removed item until the index it was removed at */
          tempList = list.slice(0, insertAt).concat(removed)

          /* add the remaining items to the list */
          const newList = tempList.concat(list.slice(
            insertAt))

          /* set state to display on page */
          setOrderedColdef(newList)
          event.target.classList.remove('over')
        }
    }
    else console.log('nothing happened')
    event.target.classList.remove('over')
  }

  const handleDragEnd = (event: any) => {
    event.target.style.opacity = 1
  }

  useEffect(()=>{
    if(Array.isArray(colDefSeq) && colDefSeq.length){
      let tempColdefList:any[] = [];
      colDefSeq?.forEach((res:any)=>{
        for (const el of orderedColDef) {
          if (el.field === res) {
            tempColdefList.push(el);
            break;
          }
        }
      })
      setOrderedColdef(tempColdefList);
    }
  },[colDefSeq])


  function sortArrayByFieldOrder(inputArray:any[], sortOrder:any[]) {
    // Create a map to store the index of each field in the sortOrder array
    const fieldIndexMap:any = {};
    for (let i = 0; i < sortOrder.length; i++) {
      fieldIndexMap[sortOrder[i]] = i;
    }
  
    // Sort the inputArray based on the sortOrder
    inputArray.sort((a:any, b:any) => {
      const indexA:any = fieldIndexMap[a.field];
      const indexB:any = fieldIndexMap[b.field];
      
      if (indexA === undefined && indexB === undefined) {
        return 0;
      } else if (indexA === undefined) {
        // If field A is not found, move it to the end
        return 1;
      } else if (indexB === undefined) {
        // If field B is not found, move it to the end
        return -1;
      } else {
        // Compare based on their index in sortOrder
        return indexA - indexB;
      }
    });
  
    return inputArray;
  }

  function sortArrayByFieldOrderDeleted(inputArray:any, sortOrder:any) {
    // Create a map to store the index of each field in the sortOrder array
    const fieldIndexMap:any = {};
    for (let i = 0; i < sortOrder.length; i++) {
      fieldIndexMap[sortOrder[i]] = i;
    }
  
    // Filter out objects that don't have a matching field in sortOrder
    const filteredArray = inputArray.filter((item:any) => fieldIndexMap[item.field] !== undefined);
  
    // Sort the filteredArray based on the sortOrder
    filteredArray.sort((a:any, b:any) => {
      const indexA = fieldIndexMap[a.field];
      const indexB = fieldIndexMap[b.field];
  
      // Compare based on their index in sortOrder
      return indexA - indexB;
    });
  
    return filteredArray;
  }

  
  


  const getFilterParameter = (event:any)=>{
    if(props.getFilteredParameter){
      props.getFilteredParameter(event.api.filterManager.getFilterModel());
    }
  }

  return (
    <>
      {props.MyRoute && props.appName && <div className="flex justify between mb-3">
        <div className="flex">
          <Tooltip placement="top" title={"Customize Table"}>
            <div className="flex justify-center items-center rounded bg-white p-2" onClick={() => { handleOpen("") }} style={{ boxShadow: '0px 0px 0px 1px rgba(70, 79, 96, 0.16), 0px 1px 1px 0px rgba(0, 0, 0, 0.10)' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3 1C1.89543 1 1 1.89543 1 3V13C1 14.1046 1.89543 15 3 15H13C14.1046 15 15 14.1046 15 13V3C15 1.89543 14.1046 1 13 1H3ZM5 6.5V9.5H2.5V6.5H5ZM2.5 10.5V13C2.5 13.2761 2.72386 13.5 3 13.5H5V10.5H2.5ZM6 10.5V13.5H9V10.5H6ZM10 10.5V13.5H13C13.2761 13.5 13.5 13.2761 13.5 13V10.5H10ZM10 9.5V6.5H13.5V9.5H10ZM9 6.5H6V9.5H9V6.5ZM4.25 4.5C4.66421 4.5 5 4.16421 5 3.75C5 3.33579 4.66421 3 4.25 3H3.25C2.83579 3 2.5 3.33579 2.5 3.75C2.5 4.16421 2.83579 4.5 3.25 4.5H4.25ZM8.25 4.5C8.66421 4.5 9 4.16421 9 3.75C9 3.33579 8.66421 3 8.25 3H6.75C6.33579 3 6 3.33579 6 3.75C6 4.16421 6.33579 4.5 6.75 4.5H8.25ZM13.5 3.75C13.5 4.16421 13.1642 4.5 12.75 4.5H10.75C10.3358 4.5 10 4.16421 10 3.75C10 3.33579 10.3358 3 10.75 3H12.75C13.1642 3 13.5 3.33579 13.5 3.75Z" fill="#616567" />
              </svg>
            </div>
          </Tooltip>
        </div>
      </div>}
      <div className="w-fit" style={grdSty}>
        <AgGridReact
          onGridReady={onGridReady}
          onCellClicked={onCellClicked}
          onCellEditingStopped={CellEditingStopped}
          rowSelection={"single"}
          ref={gridRef}
          columnDefs={colDef}
          rowData={rowData}
          onSelectionChanged={onSelectionChanged}
          animateRows={true}
          pagination={props.showPagination ? props.showPagination : false}
          paginationPageSize={props.PageSize ? props.PageSize : 20}
          onPaginationChanged={paginationChanged}
          className={props.className ? props.className : ""}
          rowHeight={props.rowHeight ? props.rowHeight : 40}
          defaultColDef={defaultColDef}
          enableBrowserTooltips={props.showTooltips ? props.showTooltips : false}
          onSortChanged={onSortChanged}
          onBodyScroll={onBodyScroll}
          rowClassRules={rowClassRules}
          onFilterChanged={getFilterParameter}
        ></AgGridReact>
      </div>



      {open && (
        <Modal open={open} size="md" onClose={handleClose}>
          <Modal.Header>
            <Modal.Title>
              <div className="font-semibold">
                Table settings
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="rounded-lg"
              style={{ backgroundColor: "#fff" }}
            >
              <div className="text-grey-700 mb-2">
                Table Column
              </div>

              <div className="flex pt-1 pb-3" style={{ overflowX: 'scroll', scrollbarWidth: 'none' }}>
                {fixedColDef.map((res: any) => {
                  if (!res.hide) {
                    return (<>
                      <div className={`p-1 px-3 mx-2 whitespace-nowrap ${res.filter ? "buttonShadowBlue" : "buttonShadowWhite"}`} onClick={() => { changeColdef(res.field) }}>
                        {res.headerName}
                      </div>
                    </>)
                  }
                  else {
                    return <></>
                  }
                })}
              </div>

              <div className="text-grey-700 mb-2">
                Column Order
              </div>
              <div className="flex pt-2 pb-2 px-2 rounded-lg" style={{ overflowX: 'scroll', scrollbarWidth: 'none', backgroundColor: "#D6D8DA" }}>
                {orderedColDef.map((res: any, index) => {
                  if (!res.hide) {
                    return (<>
                      <div id={`${index}`} key={res.field} draggable='true' onDragStart={handleDragStart} onDragOver={handleDragOver} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} onDrop={handleDrop} onDragEnd={handleDragEnd} className="py-1 pr-3 pl-2 mx-2 whitespace-nowrap buttonShadowWhite flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 10 10" fill="none" className="mr-1">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M2 10C1.72386 10 1.5 9.77614 1.5 9.5V0.5C1.5 0.223858 1.72386 0 2 0C2.27614 0 2.5 0.223858 2.5 0.5V9.5C2.5 9.77614 2.27614 10 2 10Z" fill="#D6D8DA" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M5 10C4.72386 10 4.5 9.77614 4.5 9.5V0.5C4.5 0.223858 4.72386 0 5 0C5.27614 0 5.5 0.223858 5.5 0.5V9.5C5.5 9.77614 5.27614 10 5 10Z" fill="#D6D8DA" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M8 10C7.72386 10 7.5 9.77614 7.5 9.5V0.5C7.5 0.223858 7.72386 0 8 0C8.27614 0 8.5 0.223858 8.5 0.5V9.5C8.5 9.77614 8.27614 10 8 10Z" fill="#D6D8DA" />
                        </svg>
                        {res.headerName}
                      </div>
                    </>)
                  }
                  else {
                    return <></>
                  }
                })
                }
              </div>

            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="contained" className="bg-blue-500" onClick={applyChanges}>
              Apply
            </Button>
            <Button onClick={resetColDef}>Reset</Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default NrjAgGrid;
